import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { withShopifyContext } from 'src/state/ShopifyState'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import CartIcon from 'src/components/CartIcon'
import HeaderDropdown from './components/HeaderDropdown'
import { colors, typography, animations, mq, util } from 'src/styles'
import { withCartContext, TABS } from 'src/state/CartState'

const showHide = false // show and hide header on scroll
const headerHeight = (attr = 'height', additionalHeight = 0) => util.responsiveStyles(attr, (140 + additionalHeight), (96 + additionalHeight), (96 + additionalHeight), (60 + additionalHeight))

const HeaderLogo = styled(Logo)`
	${ util.responsiveStyles('width', 180, 150, 150, 120) }
	height: auto;
  color: inherit;
`

const NavLinkStyle = (scrolled, active, hasAtf, hasDropdown) => `
	display: block;
	${ typography.h6 }
	${ util.responsiveStyles('font-size', 14, 13, 12, 12) }
	${ util.responsiveStyles('margin-right', 60, 40, 20, 20) }
	line-height: 1em;
  flex-shrink: 0;
  position: relative;
  color: inherit;
	transition: 	padding ${ animations.mediumSpeed } ease-in-out,
								margin ${ animations.mediumSpeed } ease-in-out,
                background ${ animations.mediumSpeed } ease-in-out,
								color ${ animations.mediumSpeed } ease-in-out;
  &:after {
    position: absolute;
    top: 1.3em;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    right: .075em;
    background: currentcolor;
    transform: scaleX(0);
    transform-origin: 100% center;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
  }
	${ active ? `
		&:hover {
      color: inherit;
      transform-origin: 0% center;
		}
    &:after {
      transform-origin: 0% center;
      transform: scaleX(1);
    }
	` : `` }
  &:hover {
    &:after {
      color: currentcolor;
      transform-origin: 0% center;
      transform: scaleX(1);
    }
  }
`

const NavLink = styled(Link)`
  ${ props => NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const CartIconContainer = styled.div`
  ${ mq.largeAndBelow } {
    align-self: center;
    padding-left: 10px;
    padding-bottom: 3px;
  }
  cursor: pointer;
  padding-right: 0;
  padding-bottom: 0;
  margin-right: 0;
  display: block;
	transition: padding ${ animations.mediumSpeed } ease-in-out,
							margin ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
							color ${ animations.mediumSpeed } ease-in-out;

`
const Wrapper = styled.header`
  ${ ({ scrolled, hasAtf }) => hasAtf ? `
    mix-blend-mode: unset;
    ${ scrolled ? `
      mix-blend-mode: difference;
    ` : `` }
  ` : `
    mix-blend-mode: difference;
  ` }
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 6;
  ${ ({ visibleDropdown }) => visibleDropdown ? `
    ${ headerHeight('height', 70) }
  ` : `` }
`

const HeaderBgPanel = styled.div`
  background: ${ colors.white };
  width: 100%;
  transition: height 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  transition: opacity ${ animations.mediumSpeed } ease-in-out;
  ${ headerHeight() }
  ${ ({ visible }) => visible ? `
    opacity: 1;
  ` : `
    opacity: 0;
  ` }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: height ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              mix-blend-mode ${ animations.mediumSpeed } ease-in-out;
  ${ headerHeight() }
  mix-blend-mode: unset;
  ${ ({ scrolled, hasAtf }) => hasAtf ? `
		color: ${ colors.bgColor };
  ` : `
		color: ${ colors.offwhite };
	` }
  ${ ({ hovered, theme }) => hovered && theme === 'white' ? `
    color: ${ colors.textColor };
  ` : `` }
  ${ ({ navVisible }) => navVisible && `
		transform: translate3d(0, -101%, 0);
	` }
`

const HeaderContentGrid = styled(Grid)`
  ${ mq.largeAndUp } {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Dropdown = styled(HeaderDropdown)`
  position: fixed;
  ${ headerHeight('top') }
  left: 0;
  right: 0;
  z-index: 6;
  box-shadow: 0 1px 0 0 ${ rgba(colors.green, 0.15) };
`

const LogoCol = styled.div`
	text-align: left;
  margin-left: -3px;
	a {
		display: inline-block;
		vertical-align: top;
	}
`

const NavLinks = styled.div`
  align-items: baseline;
  display: flex;
  width: 100%;
  justify-content: ${ ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start' };
`

const MenuIcon = styled.div`
	display: none;
	padding: 5px 10px;
	margin-left: -10px;
	cursor: pointer;
	span {
		display: block;
	}
	${ mq.largeAndBelow } {
		display: inline-block;
	}
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height 0.3s ease-in-out;
	${ ({ hasBanner }) => hasBanner ? `
		${ headerHeight('height', 40) }
	` : `
		${ headerHeight() }
	` }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
`

class Header extends Component {
  state = {
    scrolled: false,
    navList: false,
    drawerOpen: false,
    bannerVisible: true,
    visibleDropdown: ''
  }

  closeBanner = () => {
    this.setState({ bannerVisible: false })
  }

  toggleDropdown = event => {
    if (event) {
      this.setState({ visibleDropdown: event.target.id })
    } else {
      this.setState({ visibleDropdown: false })
    }
  }

  render () {
    const {
      location,
      hasAtf,
      bannerText,
      collapsed,
      bannerColor,
      navigation,
      hovered,
      setTheme,
      productPage,
      shopifyContext,
      cartContext,
      placeholder,
      className
    } = this.props
    const { bannerVisible, visibleDropdown } = this.state

    let pathname = '/'
    if (location) {
      pathname = location
    }

    const drawerOpen = cartContext.mobileMenuIsOpen

    const { collections, cart } = shopifyContext
    const { toggleCart, toggleMobileMenu, mobileMenuIsOpen } = cartContext
    const numberOfItemsInCart = (cart.lineItems && cart.lineItems.length) || 0

    return (
      <Fragment>
        <ScrollListener.Consumer>
          {({
            scrolledToTop,
            scrolledToBottom,
            scrollY,
            scrolledUp,
            hasScrolled,
            pageHeight,
          }) => {
            let scrolled = false

            if (setTheme !== 'white') {
              if (collapsed) {
                scrolled = true
              } else {
                scrolled = !scrolledToTop && hasScrolled && !drawerOpen
              }
            }

            return (
              <div
                onMouseLeave={() => this.toggleDropdown(false)}
                className={className}
                style={{ position: 'relative' }}
              >
                <Wrapper
                  scrolled={scrolled}
                  hasAtf={hasAtf}
                  navVisible={!scrolledUp && !scrolledToTop && showHide}
                  visibleDropdown={visibleDropdown}
                >
                  <HeaderNotificationBanner
                    closeBanner={this.closeBanner}
                    collapsed={!bannerVisible}
                    text={bannerText}
                    setTheme={bannerColor}
                  />
                  <HeaderWrapper
                    navVisible={!scrolledUp && !scrolledToTop && showHide}
                    hasAtf={hasAtf}
                    productPage={productPage}
                    scrolled={scrolled}
                    navVisible={!scrolledUp && !scrolledToTop && showHide}
                    theme={setTheme}
                    hovered={visibleDropdown}
                  >
                    <HeaderContentGrid
                      small="1 [5] 1 [6] 1"
                      // large="1 [6] 1 [17] 1"
                      large="[6] 1 [7]"
                      vAlign="center"
                      navVisible={!scrolledUp && !scrolledToTop && showHide}
                      hasAtf={hasAtf}
                      scrolled={scrolled}
                      navVisible={!scrolledUp && !scrolledToTop && showHide}
                    >
                      <LogoCol>
                        <Link to="/" title='Go to homepage'>
                          <HeaderLogo scrolled={scrolled} hasAtf={hasAtf} productPage={productPage} />
                        </Link>
                      </LogoCol>
                      <div>
                        <NavLinks alignment="right">
                          <ResponsiveComponent
                            medium={
                              <MenuIcon onClick={toggleMobileMenu}>
                                <AnimatedIcon
                                  icon={drawerOpen ? 'close' : 'menu'}
                                />
                              </MenuIcon>
                            }
                            large={navigation && navigation.map((link, index) => (
                              <Fragment key={link.id}>
                                <NavLink
                                  scrolled={scrolled}
                                  hasAtf={hasAtf}
                                  to={link.to}
                                  active={'/' + pathname === link.to}
                                  key={link.to}
                                  id={link.id}
                                  title={'Navigate to ' + link.label + ' page'}
                                  showCollectionsDropdown={link.showCollectionsDropdown}
                                  hasDropdown={link.dropdownLinks || link.showCollectionsDropdown}
                                  onMouseEnter={event => this.toggleDropdown(event)}
                                >
                                  {link.label}
                                </NavLink>
                              </Fragment>
                            ))}
                          />
                          <CartIconContainer
                            onClick={() => toggleCart(TABS.CART)}
                          >
                            <CartIcon count={numberOfItemsInCart} />
                          </CartIconContainer>
                        </NavLinks>
                      </div>
                    </HeaderContentGrid>
                  </HeaderWrapper>
                </Wrapper>
                <ResponsiveComponent
                  medium={<span/>}
                  large={navigation && navigation.map((link, index) => (
                    link.dropdownLinks && (
                      <Dropdown
                        key={link.id}
                        open={visibleDropdown === link.id}
                        items={link.dropdownLinks}
                      />
                    )
                  ))}
                />
              </div>
            )
          }}
        </ScrollListener.Consumer>

        <ConditionalRender condition={!hasAtf && placeholder !== false}>
          <HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
        </ConditionalRender>

        <HeaderBgPanel visible={visibleDropdown} />

      </Fragment>
    )
  }
}

Header.defaultProps = {
  hasAtf: false
}

export default withShopifyContext(withCartContext(Header))
